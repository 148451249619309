import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { SpanListDto } from '../../dto/span/SpanLIstDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiDeleteSpan = async (dto: SpanListDto) => {
  const params: IApiParams = {
    url: '/span/deleteInfo.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'DELETE', dto);
};
