import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';
import { ModelsDiv } from '../component/models/ModelsDiv';

export const ModelsPage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<ModelsDiv />}
  />
);
