import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  DEVICES_INFO_ROUTE,
  DEVICES_ROUTE,
  MAIN_ROUTE,
  SIGN_IN_ROUTE,
} from '../../constant/routes';
import { DevicesPage } from '../../page/DevicesPage';
import { MainPage } from '../../page/MainPage';
import { SignInPage } from '../../page/SignInPage';
import { DeviceInfoPage } from '../../page/DeviceInfoPage';
import { ModelsPage } from '../../page/ModelsPage';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
      <Route path={DEVICES_ROUTE} element={<DevicesPage />} />
      <Route path={`${DEVICES_ROUTE}/:parSiId`} element={<ModelsPage />} />
      <Route
        path={`${DEVICES_ROUTE}/:parSiId/:parModelId`}
        element={<ModelsPage />}
      />
      <Route path={`${DEVICES_INFO_ROUTE}/:id`} element={<DeviceInfoPage />} />
    </Routes>
  </div>
);

export default App;
