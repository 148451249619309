import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { OutputListDto } from '../../dto/output/OutputLIstDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiUpdateOutput = async (dto: OutputListDto) => {
  const params: IApiParams = {
    url: '/output/updateInfo.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
