import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MOBILE_VIEW } from '../../constant/constants';
import { getViewType, isMobileView } from '../../utils/utils';
import { SpeedDiaFlowmeterlDiv } from '../speed-dial/SpeedDialFlowmeterDiv';

interface Props {
  title: string;
  isFlowmeter?: boolean;
  deviceInfo?: string;
}

export const BodyHeaderDiv: FunctionComponent<Props> = ({
  title,
  isFlowmeter,
  deviceInfo,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div>
      <Stack
        style={{
          padding: '10px 0px 10px 0px',
          marginTop: '20px',
        }}
        spacing={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {location.pathname !== '/main' &&
                getViewType() !== MOBILE_VIEW &&
                !isFlowmeter && (
                  <IconButton
                    title={t('tooltipBack')}
                    sx={{ color: '#00695c' }}
                    onClick={() => window.history.back()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
              {getViewType() !== MOBILE_VIEW && isFlowmeter && (
                <SpeedDiaFlowmeterlDiv />
              )}
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: !isMobileView() ? '24px' : '16px',
                  color: '#00695c',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {title}
              </Typography>
            </Stack>
          </Stack>
          {isFlowmeter && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: !isMobileView() ? '24px' : '16px',
                  color: '#ff5722',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {deviceInfo}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Divider />
      </Stack>
    </div>
  );
};

BodyHeaderDiv.defaultProps = {
  isFlowmeter: false,
  deviceInfo: '',
};
