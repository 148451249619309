import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiGetSpan = async (siId: string, modelId?: string) => {
  const params: IApiParams = {
    url:
      modelId === undefined
        ? `/span/getSpan.php?siId=${siId}`
        : `/span/getSpan.php?siId=${siId}&modelId=${modelId}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
