import { JSON_CONTENT_TYPE, TOKEN_NAME } from '../../constant/constants';
import { ModelListDto } from '../../dto/model/ModelLIstDto';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiUpdateModel = async (dto: ModelListDto) => {
  const params: IApiParams = {
    url: '/model/updateInfo.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
