import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  getAccurType,
  getCDopuskType,
  getDefaultAlertProps,
  getPerehodniiType,
  getSpanType,
  getUnitType,
  getVidPogr,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { IAlertProps } from '../../interface/IAlertProps';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  PR_TYPE,
} from '../../constant/constants';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { MyTextFiled } from '../UI/MyTextField';
import { ErrorListDto } from '../../dto/error/ErrorLIstDto';
import { apiAddError } from '../../service/error/apiAddError';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogCreateError: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const { parSiId, parModelId } = useParams();
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [errorDto, setErrorDto] = useState<ErrorListDto>({
    id: -1,
    type: -1,
    siId: parseInt(parSiId ?? '-1', 10),
    modelId: parseInt(parModelId ?? '-1', 10),
    spanId: -1,
    outputId: -1,
    errorType: -1,
    error: '',
    npi: '',
    vpi: '',
    formType: -1,
    spanType: 0,
    dopusk: -1,
    formKoeff: '',
    unit: -1,
  });
  const dopuskTypes = getCDopuskType();
  const types = getVidPogr();
  const spanTypes = getSpanType();
  const perehodniiType = getPerehodniiType();
  const units = getUnitType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10)
  );

  const handleClose = () => {
    setErrorDto({
      id: -1,
      type: -1,
      siId: parseInt(parSiId ?? '-1', 10),
      modelId: parseInt(parModelId ?? '-1', 10),
      spanId: -1,
      outputId: -1,
      errorType: -1,
      error: '',
      npi: '',
      vpi: '',
      formType: -1,
      spanType: 0,
      dopusk: -1,
      formKoeff: '',
      unit: -1,
    });
    onClose();
    setCheckHelper(false);
  };

  const addError = async () => {
    const apiResult: IApiResult = await apiAddError(errorDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleAdd = async () => {
    if (errorDto.type === -1 || errorDto.errorType === -1) {
      setCheckHelper(true);
      return;
    }
    await addError();
  };

  useEffect(() => {
    if (open) {
      setErrorDto({
        id: -1,
        type: -1,
        siId: parseInt(parSiId ?? '-1', 10),
        modelId: parseInt(parModelId ?? '-1', 10),
        spanId: -1,
        outputId: -1,
        errorType: -1,
        error: '',
        npi: '',
        vpi: '',
        formType: -1,
        spanType: 0,
        dopusk: -1,
        formKoeff: '',
        unit: -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%' },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Погрешность
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2} height="100%" justifyContent="space-between">
            <Stack width="100%" direction="column" spacing={1}>
              <MyAutoComplete
                value={
                  errorDto.type !== undefined && errorDto.type !== -1
                    ? types[errorDto.type]
                    : ''
                }
                onChange={(e, v) =>
                  setErrorDto({
                    ...errorDto,
                    type: types.indexOf(v),
                  })
                }
                error={checkHelper}
                arrayList={types}
                size="small"
                label="Вид"
              />

              <MyAutoComplete
                value={
                  errorDto.errorType !== undefined && errorDto.errorType !== -1
                    ? getAccurType(
                        parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
                        false
                      )[errorDto.errorType]
                    : ''
                }
                onChange={(e, v) =>
                  setErrorDto({
                    ...errorDto,
                    errorType: getAccurType(
                      parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
                      false
                    ).indexOf(v),
                  })
                }
                error={checkHelper}
                arrayList={getAccurType(
                  parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
                  false
                )}
                size="small"
                label="Тип погрешности"
              />
              <MyAutoComplete
                value={
                  errorDto.spanType !== undefined && errorDto.spanType !== -1
                    ? spanTypes[errorDto.spanType]
                    : ''
                }
                onChange={(e, v) =>
                  setErrorDto({
                    ...errorDto,
                    spanType: spanTypes.indexOf(v),
                  })
                }
                error={checkHelper}
                arrayList={spanTypes}
                size="small"
                label=""
              />
              {errorDto.spanType !== 0 && (
                <MyAutoComplete
                  value={
                    errorDto.unit !== undefined && errorDto.unit !== -1
                      ? perehodniiType[errorDto.unit]
                      : ''
                  }
                  onChange={(e, v) =>
                    setErrorDto({
                      ...errorDto,
                      unit: perehodniiType.indexOf(v),
                    })
                  }
                  error={checkHelper}
                  arrayList={perehodniiType}
                  size="small"
                  label="Единица измерений переходной точки"
                />
              )}
              {errorDto.type !== 7 && (
                <MyTextFiled
                  value={errorDto.error}
                  onChange={(e) =>
                    setErrorDto({
                      ...errorDto,
                      error: e.target.value,
                    })
                  }
                  error={checkHelper}
                  type={errorDto.spanType === 0 ? 'number' : 'text'}
                  label={
                    errorDto.spanType === 0
                      ? 'Погрешность'
                      : 'Погрешности (через ;)'
                  }
                  size="small"
                />
              )}
              {errorDto.type === 7 && (
                <MyAutoComplete
                  value={
                    errorDto.dopusk !== undefined && errorDto.dopusk !== -1
                      ? dopuskTypes[errorDto.dopusk]
                      : ''
                  }
                  onChange={(e, v) =>
                    setErrorDto({
                      ...errorDto,
                      dopusk: dopuskTypes.indexOf(v),
                    })
                  }
                  error={checkHelper}
                  arrayList={dopuskTypes}
                  size="small"
                  label="Класс допуска"
                />
              )}

              {errorDto.type !== 7 && errorDto.spanType !== 0 && (
                <MyTextFiled
                  value={errorDto.npi}
                  onChange={(e) =>
                    setErrorDto({
                      ...errorDto,
                      npi: e.target.value,
                    })
                  }
                  error={checkHelper}
                  type="text"
                  label="Переходные точки (через ;)"
                  size="small"
                />
              )}
              {false &&
                errorDto.type !== 7 &&
                errorDto.spanType !== 0 &&
                errorDto.spanType !== 3 && (
                  <MyTextFiled
                    value={errorDto.vpi}
                    onChange={(e) =>
                      setErrorDto({
                        ...errorDto,
                        vpi: e.target.value,
                      })
                    }
                    error={checkHelper}
                    type="number"
                    label={
                      errorDto.spanType !== 2
                        ? 'Переходная точка'
                        : 'Переходная точка (до)'
                    }
                    size="small"
                  />
                )}
              {false &&
                errorDto.type !== 7 &&
                errorDto.spanType !== 0 &&
                errorDto.spanType !== 1 && (
                  <MyAutoComplete
                    value={
                      errorDto.unit !== undefined && errorDto.unit !== -1
                        ? units[errorDto.unit]
                        : ''
                    }
                    onChange={(e, v) =>
                      setErrorDto({
                        ...errorDto,
                        unit: units.indexOf(v),
                      })
                    }
                    error={checkHelper}
                    arrayList={units}
                    size="small"
                    label="Единица измерений"
                  />
                )}
            </Stack>
            <MyButton
              text="Добавить"
              background={MAIN_COLOR_STRING}
              onClick={handleAdd}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
