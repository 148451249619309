import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyTextFiled } from '../UI/MyTextField';
import { MyButton } from '../UI/MyButton';
import { IAlertProps } from '../../interface/IAlertProps';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
} from '../../constant/constants';
import { ModelListDto } from '../../dto/model/ModelLIstDto';
import { apiUpdateModel } from '../../service/model/apiUpdateModel';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: ModelListDto;
}

export const DialogModelMore: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const { parSiId, parModelId } = useParams();
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [modelDto, setModelDto] = useState<ModelListDto>({
    id: -1,
    siId: parseInt(parSiId ?? '-1', 10),
    name: '',
    modelId: parseInt(parModelId ?? '-1', 10),
  });

  const handleClose = () => {
    setModelDto({
      id: -1,
      siId: parseInt(parSiId ?? '-1', 10),
      name: '',
      modelId: parseInt(parModelId ?? '-1', 10),
    });
    onClose();
    setCheckHelper(false);
  };

  const updateModel = async () => {
    const apiResult: IApiResult = await apiUpdateModel(modelDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleUpdate = async () => {
    if (modelDto.name === '') {
      setCheckHelper(true);
      return;
    }
    await updateModel();
  };

  useEffect(() => {
    if (open) {
      setModelDto({
        id: dto?.id ?? -1,
        siId: dto?.siId ?? -1,
        name: dto?.name ?? '',
        modelId: dto?.modelId ?? -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Редактирование
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="column" spacing={1}>
              <MyTextFiled
                value={modelDto.name}
                onChange={(e) =>
                  setModelDto({
                    ...modelDto,
                    name: e.target.value,
                  })
                }
                error={checkHelper}
                label="Наименование"
                size="small"
              />
            </Stack>
            <MyButton
              text="Сохранить"
              background={MAIN_COLOR_STRING}
              onClick={handleUpdate}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogModelMore.defaultProps = {
  dto: undefined,
};
