import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDefaultAlertProps, getOutputType } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { IAlertProps } from '../../interface/IAlertProps';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  PR_TYPE,
} from '../../constant/constants';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { OutputListDto } from '../../dto/output/OutputLIstDto';
import { apiAddOutput } from '../../service/output/apiAddOutput';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogCreateOutput: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const { parSiId, parModelId } = useParams();
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [outputDto, setOutputDto] = useState<OutputListDto>({
    id: -1,
    siId: parseInt(parSiId ?? '-1', 10),
    outputType: -1,
    modelId: parseInt(parModelId ?? '-1', 10),
  });
  const outputTypes = getOutputType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10)
  );

  const handleClose = () => {
    setOutputDto({
      id: -1,
      siId: parseInt(parSiId ?? '-1', 10),
      outputType: -1,
      modelId: parseInt(parModelId ?? '-1', 10),
    });
    onClose();
    setCheckHelper(false);
  };

  const addOutput = async () => {
    const apiResult: IApiResult = await apiAddOutput(outputDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleAdd = async () => {
    if (outputDto.outputType === -1) {
      setCheckHelper(true);
      return;
    }
    await addOutput();
  };

  useEffect(() => {
    if (open) {
      setOutputDto({
        id: -1,
        siId: parseInt(parSiId ?? '-1', 10),
        outputType: -1,
        modelId: parseInt(parModelId ?? '-1', 10),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '300px' },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Выходной сигнал
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2} height="100%" justifyContent="space-between">
            <Stack width="100%" direction="column" spacing={1}>
              <MyAutoComplete
                value={
                  outputDto.outputType !== undefined &&
                  outputDto.outputType !== -1
                    ? outputTypes[outputDto.outputType]
                    : ''
                }
                onChange={(e, v) =>
                  setOutputDto({
                    ...outputDto,
                    outputType: outputTypes.indexOf(v),
                  })
                }
                error={checkHelper}
                arrayList={outputTypes}
                size="small"
                label="Выходной сигнал"
              />
            </Stack>
            <MyButton
              text="Добавить"
              background={MAIN_COLOR_STRING}
              onClick={handleAdd}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
